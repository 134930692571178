<template>
  <PromiseContainer :promises.sync="promises">
    <overview
        :items="users"
        :headers="headers"
        :filters="filters"
        header-text="Werknemers"
        add-btn-text="Nieuwe werknemer"
        :add-item-route="{name: RouteNames.USER_CREATE}"
        :detail-page-function="detailPageFunction"
        local-storage-prefix="user">
      <template v-slot:item.name="{item}">
            <avatar :initials="formatUserToInitials(item)" :userId="item.id" :SASKey="SASKey" :avatar="item.avatar"/>
            <span class="pl-2">{{ item.name }}</span>
      </template>
      <template v-slot:item.status="{item}">
        <v-chip :color="item.active ? 'green': 'red'" label>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.teams="{item}">
        <v-chip label v-for="(team, index) in item.teams" :key="index" :class="{ 'ml-2': index > 0 }">
          {{ team }}
        </v-chip>
      </template>
    </overview>
  </PromiseContainer>
</template>

<script>
import Overview from "@/components/shared/Overview";
import {formatUserToInitials} from "@/shared/utils/userUtils";
import Avatar from "@/components/shared/Avatar";
import RouteNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";

export default {
  name: "UserOverview.vue",
  components: {
    PromiseContainer,
    Overview,
    Avatar
  },
  data() {
    return {
      headers: [
        {
          text: 'Naam',
          value: 'name',
          cellClass: 'notranslate'
        },
        {
          text: 'Email',
          value: 'email',
          cellClass: 'notranslate'
        },
        {
          text: 'Functie',
          value: 'jobTitle'
        },
        {
          text: 'Teams',
          value: 'teams',
          cellClass: 'notranslate'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      promises: [this.fetchUsers(), this.$store.dispatch("fileExportModule/fetchSASKey").then(result => this.SASKey = result)],
      filters: [],
      users: [],
      SASKey: null,
      formatUserToInitials: formatUserToInitials,
      detailPageFunction: (user) => {
        return {
          name: RouteNames.USER_DETAIL,
          params: {
            userId: user.id
          }
        }
      },
      RouteNames,
    }
  },
  computed: {
    getFilterTeamsOptions() {
      const teams = [...new Set(this.users.filter(user => user.teams).flatMap(user => user.teams))];
      return teams.map(team => {
        return {
          id: 'team#' + team,
          label: team,
          active: false,
          predicate: (user) => user.teams && user.teams.includes(team)
        }
      }).sort((a, b) => a.label.localeCompare(b.label))
    },
    getFilterJobTitleOptions() {
      const jobTitles = [...new Set(this.users.filter(user => user.jobTitle).map(user => user.jobTitle))];
      return jobTitles.map(jobTitle => {
        return {
          id: 'jobTitle#' + jobTitle,
          label: jobTitle,
          predicate: (user) => user.jobTitle && user.jobTitle === jobTitle
        }
      }).sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  methods: {
    fetchUsers() {
      return this.$store.dispatch('usersModule/fetchUsers')
          .then((data) => {
            this.users = this.mapUsers(data)
            this.resetFilters()
          })
    },
    mapUsers(unmappedUsers) {
      if (!unmappedUsers) {
        return [];
      }
      unmappedUsers = unmappedUsers.map(user => ({
        ...user,
        status: user.active ? 'actief' : 'inactief'
      }))
      unmappedUsers.sort(function (a, b) {
        if (a.active === b.active) {
          return a.name.localeCompare(b.name)
        }
        return b.active - a.active
      })
      return unmappedUsers;
    },
    resetFilters() {
      this.filters = [
        {
          title: 'Status',
          options: [
            {
              id: 'status#active',
              label: 'Actief',
              active: false,
              predicate: (client) => client.active,
            },
            {
              id: 'status#inactive',
              label: 'Inactief',
              active: false,
              predicate: (client) => !client.active,
            }
          ]
        },
        {
          id: 'team',
          title: 'Team',
          options: this.getFilterTeamsOptions
        },
      ]
      if (this.getFilterJobTitleOptions.length > 0) {
        this.filters.push({
          title: 'Functie',
          options: this.getFilterJobTitleOptions
        });
      }
    },
  }
}
</script>
